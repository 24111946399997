<template>
  <div>
    <template v-if="d_selectedProject.id && d_searchProjectList.length && !d_showList">
      <b-row>
        <b-col sm="12" lg="9">
        </b-col>
        <b-col sm="12" lg="3">
          <b-button @click="d_showList = !d_showList" variant="outline-primary" style="width: 100%; margin-top:10px;">
            <i class="fa fa-search"></i>
          </b-button>
        </b-col>
      </b-row>
    </template>
    <template v-if="d_showList">
      <b-row>
        <b-col sm="12" lg="10">
          <b-form-input v-model="d_searchText" placeholder="Search projects..."></b-form-input>
        </b-col>
        <b-col sm="12" lg="2">
          <b-button v-if="!d_isSearching" @click="f_search()" variant="outline-primary" style="width: 100%;">
            <i class="fa fa-search"></i>
          </b-button>
          <b-button v-if="d_isSearching" style="width: 100%;" variant="outline-primary" disabled>
            <b-spinner variant="primary" style="width: 18px; height: 18px;" label="Searching"></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </template>
    <template v-if="d_isSearched && d_showList && d_searchProjectList.length">
      <b-row style="margin: 15px 0px;">
        <b-col sm="12" lg="12" style="padding: 0px;">
          {{ StoreLangTranslation.data['search_results'][StoreLang] }}
        </b-col>
      </b-row>
      <template v-for="(project, project_index) in d_searchProjectList">
        <b-row style="margin: 15px 0px;">
          <b-col sm="12" lg="1">
            {{ project_index+1 }} )
          </b-col>
          <b-col sm="12" lg="11">
            <span @click="f_selectProject(project)" style="cursor: pointer;"> {{ project.name }} </span>
          </b-col>
        </b-row>
        <hr/>
      </template>
    </template>
    <template v-else-if="d_isSearched && !d_searchProjectList.length">
      <b-row style="margin: 15px 0px;">
        <b-col sm="12" lg="12">
          {{ StoreLangTranslation.data['search_not_found'][StoreLang] }}
        </b-col>
      </b-row>
    </template>
    <template v-if="d_selectedProject.id && d_applicationFrontendUri && !d_showList">
      <wanalyzer-cell-calculate :p_projectId="d_selectedProject.id" :p_idType="d_idType" p_screenModeChange='no' p_screenMode='2'></wanalyzer-cell-calculate>
    </template>
  </div>
</template>

<script>
import { ServiceFunctions } from '@/wam/modules/service';
import { mapGetters } from 'vuex';
import {
  default as WanalyzerCellCalculate
} from "@/components/widgets/WanalyzerCellCalculate";
export default {
  name: 'WaiProjectSearch',
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreDevice: 'StoreDevice',
    StoreCurrencyType: 'StoreCurrencyType',
  }),
  props: {
    p_searchType: {
      type: String,
      required: true
    }
  },
  components: {
    WanalyzerCellCalculate
  },
  data () {
    return {
      d_searchText: '',
      d_searchProjectList: [],
      d_showList: true,
      d_selectedProject: {},
      d_isSearching: false,
      d_isSearched: false,
      d_frontendLoading: false,
      d_applicationFrontendUri: '',
      d_searchType: '',
      d_iframeLink: '',
      d_idType: ''
    }
  },
  created: function () {
    this.d_applicationFrontendUri = this.StoreFrontendSettings.data.application_frontend_uri;
    this.d_searchType = this.p_searchType;
    if (this.d_searchType == '0') {
      // Şimdilik hepsi durumu client id based olarak eklendi. Daha sonra update edilebilir.
      this.d_idType = 'client_id_based';
    } else if (this.d_searchType == '1') {
      this.d_idType = 'client_id_based';
    } else if (this.d_searchType == '2') {
      this.d_idType = 'no';
    }
  },
  mounted: function () {
    //
  },
  methods: {
    f_search: function () {
      this.d_isSearching = true;
      let query = 'search_type=' + this.d_searchType; // 0: hepsi, 1: yalnızca client id bazlı, 2: yalnızca client id bazlı olmayanlar
      let data = {
        'search_text': this.d_searchText
      };
      ServiceFunctions.wai_search(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            // console.log(resp.data);
            this.d_searchProjectList = resp.data.list;
            this.d_selectedProject = {};
            this.d_showList = true;
            this.d_isSearched = true;
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
          this.d_isSearching = false;
        });
    },
    f_selectProject: function (project) {
      this.d_selectedProject = project;
      this.d_showList = false;
      this.d_iframeLink = this.d_applicationFrontendUri + '/wanalyzer-cell-calculate-iframe?project_id=' + this.d_selectedProject.id + '&screen_mode=1';
    }
  },
  watch: {
  }
}
</script>
