<template>
  <div class="fade-in-page" v-if="d_frdWebUnitWdm">
    <!-- sayfanın adı ve düzenleme tarihini aşağıdaki row gösteriyor -->
<!--     <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ p_WebsiteUnitData.data['0']['4']['lang'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'">
        {{ f_dateFormat(p_WebsiteUnitData.updated_at) }}
      </b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row> -->
    <!-- <div style="height: 40px;"></div> -->
    <b-row v-if="StoreWebsiteEdit === 'yes'">
      <b-col sm="12" lg="12" style="text-align: center;">
        <b-button variant="outline-primary" @click="f_frontendWebsiteUnitUpdate()" style="min-width: 300px;">
          {{ StoreLangTranslation.data['save_changes'][StoreLang] }}
        </b-button>
      </b-col>
    </b-row>
    <h3 v-if="p_WebsiteUnitData && p_WebsiteUnitData.data && p_WebsiteUnitData.data['0'] && p_WebsiteUnitData.data['0']['5'] && p_WebsiteUnitData.data['0']['5']['lang']" style="text-align: center;"> {{ p_WebsiteUnitData.data['0']['5']['lang'][StoreLang] }} </h3>

    <template v-if="p_WebsiteUnitData && p_WebsiteUnitData.data && p_WebsiteUnitData.data['0'] && p_WebsiteUnitData.data['0']['7'] && p_WebsiteUnitData.data['0']['7']['list'] && p_WebsiteUnitData.data['0']['7']['list'].length > 0">


      <template v-for="(page_info, page_info_index) in p_WebsiteUnitData.data['0']['7']['list']">
        <!-- {{ page_info }} -->

        <div v-if="StoreWebsiteEdit === 'yes'" style="background-color: lightblue; margin: 20px; padding: 5px;">
          <b-row>
            <b-col sm="12" lg="2">
              <strong>
                {{ page_info_index + 1 }} -
              </strong>
              <strong>{{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['27']['name']['translation'][StoreLang] }}</strong>
            </b-col>
            <b-col sm="12" lg="6">
              <b-form-select v-model="page_info['27'].val.value" style="background-color: #edf9ff;" @change="$forceUpdate()">
                <option v-for="(op, op_index) in d_frdWebUnitWdm['0']['parameters']['7']['parameters']['27'].options" :value="op.value">
                  [{{ op.value }}] {{ op.translation[StoreLang] }}
                </option>
              </b-form-select>
            </b-col>
            <b-col sm="12" lg="4" style="text-align: right;">
              <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(p_WebsiteUnitData.data['0']['7']['list'], page_info_index)">
                <i class="fa fa-copy"></i>
              </b-button>
              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(p_WebsiteUnitData.data['0']['7']['list'], page_info_index, 'up')">
                <i class="fa fa-arrow-up"></i>
              </b-button>
              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(p_WebsiteUnitData.data['0']['7']['list'], page_info_index, 'down')">
                <i class="fa fa-arrow-down"></i>
              </b-button>
              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(p_WebsiteUnitData.data['0']['7']['list'], page_info_index, 'top')">
                <i class="fa fa-arrow-circle-up"></i>
              </b-button>
              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(p_WebsiteUnitData.data['0']['7']['list'], page_info_index, 'bottom')">
                <i class="fa fa-arrow-circle-down"></i>
              </b-button>
              <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(p_WebsiteUnitData.data['0']['7']['list'], page_info_index)">
                <i class="fa fa-trash"></i>
              </b-button>
            </b-col>
          </b-row>
        </div>


        <b-row>
          <b-col sm="12" md="2" lg="2"> </b-col>
          <b-col sm="12" md="8" lg="8">
            <div v-if="StoreWebsiteEdit === 'yes'">
              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, '', '8', page_info, true)">
                {{ StoreLangTranslation.data['component_name'][StoreLang] }}
              </i>
            </div>
            <template v-if="page_info_index === d_editPageInfoIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '8'">
                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                  <b-col sm="12" lg="2">
                    {{ lang.label }}
                  </b-col>
                  <b-col sm="12" lg="10">
                    <input v-if="page_info['8']" type="text" class="form-control" v-model="page_info['8']['lang'][lang.label]">
                  </b-col>
                </b-row>
            </template>
            <template v-else>
              <template v-if="page_info['8'] && page_info['8'].lang && page_info['8'].lang[StoreLang]">
                <template v-if="page_info['8'].lang[StoreLang].indexOf('</') !== -1">
                  <div v-html="page_info['8'].lang[StoreLang]"></div>
                </template>
                <template v-else>
                  <h3> {{ page_info['8'].lang[StoreLang] }} </h3>
                </template>
              </template>
            </template>
          </b-col>
          <b-col sm="12" md="2" lg="2"> </b-col>
        </b-row>

        <b-row v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '17'">
          <b-col sm="12" md="2" lg="2"> </b-col>
          <b-col sm="12" md="8" lg="8">
            <div v-if="StoreWebsiteEdit === 'yes'">
              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, '', '33', page_info, false)">
                {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['33']['name']['translation'][StoreLang] }}
              </i>
            </div>
            <template v-if="page_info_index === d_editPageInfoIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '33'">
              <b-form-select v-model="page_info['33'].val.value" style="background-color: #edf9ff;" @change="$forceUpdate()">
                <option v-for="(op, op_index) in d_frdWebUnitWdm['0']['parameters']['7']['parameters']['33'].options" :value="op.value">
                  [{{ op.value }}] {{ op.translation[StoreLang] }}
                </option>
              </b-form-select>
            </template>
            <template v-else>
              <template v-if="StoreWebsiteEdit === 'yes'">
                <div v-if="page_info['33'] && page_info['33'].val && page_info['33'].val.value">
                  {{ f_getOptionLabel(page_info['33']['val']['value'], d_frdWebUnitWdm['0']['parameters']['7']['parameters']['33'].options) }}
                </div>
              </template>
            </template>
          </b-col>
          <b-col sm="12" md="2" lg="2"> </b-col>
        </b-row>

          <!-- general -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '0'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8" style="background-color: white;">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                    <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                      <div v-if="StoreWebsiteEdit === 'yes'">
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-copy"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                            <i class="fa fa-arrow-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                            <i class="fa fa-arrow-down"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                            <i class="fa fa-arrow-circle-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                            <i class="fa fa-arrow-circle-down"></i>
                          </b-button>
                          <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </div>
                      <b-row style="margin-top: 10px; margin-bottom: 10px;">
                        <b-col cols="12" style="padding: 10px;">


                          <div v-if="StoreWebsiteEdit === 'yes'">
                            <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                              {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                            </i>
                          </div>
                          <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                              <b-row>
                                <b-col cols="12">
                                  <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                  <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                </b-col>
                              </b-row>
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="2">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="10">
                                  <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                    <template v-if="d_inputEditorMode == 'text'">
                                      <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                    </template>
                                    <template v-else-if="d_inputEditorMode == 'html'">
                                      <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                    </template>
                                  </template>
                                </b-col>
                              </b-row>
                          </template>
                          <template v-else>
                            <b-row v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                              <b-col cols="12">
                                <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <h6 style="font-weight: 600;"> {{ page_info_detail['10']['lang'][StoreLang] }} </h6>
                                </template>
                              </b-col>
                            </b-row>
                          </template>

                          <div v-if="StoreWebsiteEdit === 'yes'">
                            <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                              {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                            </i>
                          </div>
                          <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                              <b-row>
                                <b-col cols="12">
                                  <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                  <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                </b-col>
                              </b-row>
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="2">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="10">
                                  <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                    <template v-if="d_inputEditorMode == 'text'">
                                      <b-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                    </template>
                                    <template v-else-if="d_inputEditorMode == 'html'">
                                      <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                    </template>
                                  </template>
                                </b-col>
                              </b-row>
                          </template>
                          <template v-else>
                            <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                              <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                              </template>
                              <template v-else>
                                <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                              </template>
                            </template>
                          </template>


                            <div v-if="StoreWebsiteEdit === 'yes'">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <input v-if="page_info_detail['13']" type="text" class="form-control" v-model="page_info_detail['13']['lang'][lang.label]">
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <div style="margin-top: 30px;">
                                <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                  <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                    {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                  </a>
                                </template>
                              </div>
                            </template>


                          <div v-if="StoreWebsiteEdit === 'yes'">
                            <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                              {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                            </i>
                          </div>
                          <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="2">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="10">
                                  <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                                </b-col>
                              </b-row>
                          </template>
                          <b-row v-if="f_getImageLink(page_info_detail)">
                            <b-col cols="12">
                              <img :src="f_getImageLink(page_info_detail)" style="height: 250px;">
                            </b-col>
                          </b-row>

                          
                            <div v-if="StoreWebsiteEdit === 'yes'">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '12', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_code'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '12'">
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <b-textarea v-if="page_info_detail['12']" v-model="page_info_detail['12']['lang'][lang.label]"></b-textarea>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <div v-if="page_info_detail['12']" class="code-text" v-html="page_info_detail['12']['lang'][StoreLang]" contenteditable="true"></div>
                            </template>
                          

                            <div v-if="StoreWebsiteEdit === 'yes'">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '17', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_mail'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '17'">
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <input v-if="page_info_detail['17']" type="text" class="form-control" v-model="page_info_detail['17']['lang'][lang.label]">
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <b-row v-if="page_info_detail['17'] && page_info_detail['17']['lang'][StoreLang]" style="margin-top: 10px;">
                                <b-col cols="12">
                                  <cite :title="page_info_detail['17']['lang'][StoreLang]"><a :href="'mailto:' + page_info_detail['17']['lang'][StoreLang] + '?Subject='" target="_top">{{ page_info_detail['17']['lang'][StoreLang] }}</a></cite>
                                </b-col>
                              </b-row>
                            </template>


                            <div v-if="StoreWebsiteEdit === 'yes'">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '18', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_iframe'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '18'">
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <b-textarea v-if="page_info_detail['18']" v-model="page_info_detail['18']['lang'][lang.label]"></b-textarea>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <b-row v-if="page_info_detail['18'] && page_info_detail['18']['lang'][StoreLang]" style="margin-top: 10px;">
                                <b-col cols="12">
                                  <iframe :src="page_info_detail['18']['lang'][StoreLang]" width="100%" height="500" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
                                </b-col>
                              </b-row>
                            </template>

                            <div v-if="StoreWebsiteEdit === 'yes'">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '28', page_info_detail, false)">
                                {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['28']['name']['translation'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '28'">
                                <b-row>
                                  <b-col sm="12" lg="12">
                                    <b-form-select v-model="page_info_detail['28']['val']['value']" style="background-color: #edf9ff;" @change="f_showWanalyzerComponents()">
                                      <option v-for="(op, op_index) in d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['28'].options" :value="op.value">
                                        [{{ op.value }}] {{ op.translation[StoreLang] }}
                                      </option>
                                    </b-form-select>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="StoreWebsiteEdit === 'yes'">
                                <div v-if="page_info_detail['28'] && page_info_detail['28'].val && page_info_detail['28'].val.value">
                                  {{ f_getOptionLabel(page_info_detail['28']['val']['value'], d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['28'].options) }}
                                </div>
                              </template>
                            </template>


                            <div v-if="StoreWebsiteEdit === 'yes'">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '21', page_info_detail, true)">
                                {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['21']['name']['translation'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '21'">
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <b-textarea v-if="page_info_detail['21']" v-model="page_info_detail['21']['lang'][lang.label]"></b-textarea>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="StoreWebsiteEdit === 'yes'">
                                <div v-if="page_info_detail['21'] && page_info_detail['21'].val">
                                  {{ page_info_detail['21'].val }}
                                </div>
                              </template>
                            </template>


                            <template v-if="page_info_detail['28'] && page_info_detail['28']['val'] && page_info_detail['28']['val']['value'] === '0'">
                              <div v-if="StoreWebsiteEdit === 'yes'">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '29', page_info_detail, false)">
                                  {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['29']['name']['translation'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '29'">
                                  <b-row>
                                    <b-col sm="12" lg="12">
                                      <b-form-select v-if="page_info_detail['29']['val']" v-model="page_info_detail['29']['val']['value']" style="background-color: #edf9ff;" @change="f_showWanalyzerComponents()">
                                        <option v-for="(op, op_index) in d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['29'].options" :value="op.value">
                                          [{{ op.value }}] {{ op.translation[StoreLang] }}
                                        </option>
                                      </b-form-select>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <template v-if="StoreWebsiteEdit === 'yes'">
                                  <div v-if="page_info_detail['29'] && page_info_detail['29'].val && page_info_detail['29'].val.value">
                                    {{ f_getOptionLabel(page_info_detail['29']['val']['value'], d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['29'].options) }}
                                  </div>
                                </template>
                              </template>

                            </template>

                            <template v-if="page_info_detail['28'] && page_info_detail['28']['val'] && page_info_detail['28']['val']['value'] === '1'">
                              <div v-if="StoreWebsiteEdit === 'yes'">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '32', page_info_detail, true)">
                                  {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['32']['name']['translation'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '32'">
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <b-textarea v-if="page_info_detail['32']" v-model="page_info_detail['32']['lang'][lang.label]"></b-textarea>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <template v-if="StoreWebsiteEdit === 'yes'">
                                  <div v-if="page_info_detail['32'] && page_info_detail['32'].val">
                                    {{ page_info_detail['32'].val }}
                                  </div>
                                </template>
                              </template>

                            </template>

                            <template v-if="page_info_detail['28'] && page_info_detail['28']['val'] && page_info_detail['28']['val']['value'] === '4'">
                              <div v-if="StoreWebsiteEdit === 'yes'">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '20', page_info_detail, true)">
                                  {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['20']['name']['translation'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '20'">
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <b-textarea v-if="page_info_detail['20']" v-model="page_info_detail['20']['lang'][lang.label]"></b-textarea>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <template v-if="StoreWebsiteEdit === 'yes'">
                                  <div v-if="page_info_detail['20'] && page_info_detail['20'].val">
                                    {{ page_info_detail['20'].val }}
                                  </div>
                                </template>
                              </template>
                            </template>

                              <!--
                                  0 kolon girdileriyle analiz çıktısı alma  getting analysis output with column inputs
                                  1 wanalyzer istatistik görüntüle  wanalyzer stats view
                                  2 wanalyzer dataset filtrele  wanalyzer dataset filter
                                  3 wanalyzer dataset istatistik sorgula  wanalyzer dataset stats query
                                  4 wanalyzer makine öğrenmesi sorgula  wanalyzer machine learning query
                                  5 wanalyzer sorgu oluşturma wanalyzer create query
                              -->

                            <b-row v-if="d_showWanalyzerComponents" style="margin-top: 10px;">
                              <b-col cols="12">
                                <template v-if="page_info_detail['21'] && page_info_detail['21']['lang'] && page_info_detail['21']['lang'][StoreLang]">
                                  <template v-if="page_info_detail['28'] && page_info_detail['28']['val'] && page_info_detail['28']['val']['value']">
                                    <template v-if="page_info_detail['28']['val']['value'] === '0' && f_wanalzyerProjectIdType(page_info_detail)">
                                      <wanalyzer-cell-calculate :key="'1_' + page_info_index + '_' + page_info_detail_index" :p_projectId="page_info_detail['21']['lang'][StoreLang]" :p_idType="f_wanalzyerProjectIdType(page_info_detail)"></wanalyzer-cell-calculate>
                                    </template>
                                    <template v-else-if="page_info_detail['28']['val']['value'] === '1'">
                                      <template v-if="page_info_detail['32'] && page_info_detail['32']['lang'] && page_info_detail['32']['lang'][StoreLang]">
                                        <wanalyzer-stats-view :key="'1_' + page_info_index + '_' + page_info_detail_index" :p_projectId="page_info_detail['21']['lang'][StoreLang]" :p_statsId="page_info_detail['32']['lang'][StoreLang]"></wanalyzer-stats-view>
                                      </template>                                
                                    </template>
                                    <template v-else-if="page_info_detail['28']['val']['value'] === '2'">
                                        <wanalyzer-dataset-analyze :key="'2_' + page_info_index + '_' + page_info_detail_index" :p_projectId="page_info_detail['21']['lang'][StoreLang]" :p_selectedProjectType="'wanalyzer_dataset_filter'"></wanalyzer-dataset-analyze>
                                    </template>
                                    <template v-else-if="page_info_detail['28']['val']['value'] === '3'">
                                        <wanalyzer-dataset-analyze :key="'3_' + page_info_index + '_' + page_info_detail_index" :p_projectId="page_info_detail['21']['lang'][StoreLang]" :p_selectedProjectType="'wanalyzer_dataset_stats_query'"></wanalyzer-dataset-analyze>
                                    </template>
                                    <template v-else-if="page_info_detail['28']['val']['value'] === '4'">
                                      <template v-if="page_info_detail['20'] && page_info_detail['20']['lang'] && page_info_detail['20']['lang'][StoreLang]">
                                        <machine-learning-query :key="'4_' + page_info_index + '_' + page_info_detail_index" :p_projectId="page_info_detail['21']['lang'][StoreLang]" :p_machineLearningId="page_info_detail['20']['lang'][StoreLang]"></machine-learning-query>
                                      </template>
                                    </template>
                                  </template>
                                </template>
                              </b-col>
                            </b-row>

                        </b-col>
                      </b-row>
                    </template>
                  </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
        </template>


          <!-- slider -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '1'">
            <div class="wrapper">
              <div class="animated fadeIn" >
                <b-carousel id="carousel1"
                            style="text-shadow: 1px 1px 2px #333; height: 100%"
                            controls
                            indicators
                            background="#ababab"
                            :interval="d_slidingInterval"
                            img-width=""
                            :img-height="500"
                            v-model="d_slideInfo"
                            @sliding-start="onSlideStart"
                            @sliding-end="onSlideEnd"
                >
                  <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                    <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">

                      <b-carousel-slide>
                          <div v-if="StoreWebsiteEdit === 'yes'">
                              <b-button size="sm" style="margin-right: 1px;" variant="primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                                <i class="fa fa-copy"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                                <i class="fa fa-arrow-up"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                                <i class="fa fa-arrow-down"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                                <i class="fa fa-arrow-circle-up"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                                <i class="fa fa-arrow-circle-down"></i>
                              </b-button>
                              <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                                <i class="fa fa-trash"></i>
                              </b-button>
                            </div>
                          <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                            <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                              {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                            </i>
                          </div>
                          <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="2">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="10">
                                  <b-textarea v-if="page_info_detail['10']" v-model="page_info_detail['10']['lang'][lang.label]"></b-textarea>
                                </b-col>
                              </b-row>
                          </template>
                          <template v-else>
                            <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                              <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                              </template>
                              <template v-else>
                                <h6>{{ page_info_detail['10']['lang'][StoreLang] }}</h6>
                              </template>
                            </template>
                          </template>
                          <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                            <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                              {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                            </i>
                          </div>
                          <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="2">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="10">
                                  <b-form-textarea v-if="page_info_detail['26']" v-model="page_info_detail['26']['lang'][lang.label]"></b-form-textarea>
                                </b-col>
                              </b-row>
                          </template>
                          <template v-else>
                            <template v-if="page_info_detail['26'] && page_info_detail['26']['lang'][StoreLang]">
                              <template v-if="page_info_detail['26']['lang'][StoreLang].indexOf('</') !== -1">
                                <div v-html="page_info_detail['26']['lang'][StoreLang]"></div>
                              </template>
                              <template v-else>
                                <div> {{ page_info_detail['26']['lang'][StoreLang] }} </div>
                              </template>
                            </template>
                          </template>

                        <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                          <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                            {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                          </i>
                        </div>
                        <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                              <b-col sm="12" lg="2">
                                {{ lang.label }}
                              </b-col>
                              <b-col sm="12" lg="10">
                                <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                              </b-col>
                            </b-row>
                        </template>
                        <template #img>
                          <img
                            class="d-block w-100"
                            height="100%"
                            :src="f_getImageLink(page_info_detail)"
                            alt="image slot"
                          >
                        </template>

                      </b-carousel-slide>
                    </template>
                  </template>
                </b-carousel>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>


          <!-- 4column_textview_linkontext -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '2'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <b-row>
                        <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                          <b-col sm="12" md="12" lg="3" style="text-align: center; padding: 10px;">
                            <div v-if="StoreWebsiteEdit === 'yes'">
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                                <i class="fa fa-copy"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                                <i class="fa fa-arrow-up"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                                <i class="fa fa-arrow-down"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                                <i class="fa fa-arrow-circle-up"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                                <i class="fa fa-arrow-circle-down"></i>
                              </b-button>
                              <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                                <i class="fa fa-trash"></i>
                              </b-button>
                            </div>
                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <h1>{{ page_info_detail['10']['lang'][StoreLang] }}</h1>
                                </template>
                              </template>
                            </template>

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-form-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-form-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <input v-if="page_info_detail['13']" type="text" class="form-control" v-model="page_info_detail['13']['lang'][lang.label]">
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-if="!(page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && ['13', '26'].indexOf(d_editParameter) !== -1)">
                              <template v-if="page_info_detail['26'] && page_info_detail['26']['lang'][StoreLang]">
                                <template v-if="page_info_detail['26']['lang'][StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['26']['lang'][StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                    <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                      <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                    </a>
                                  </template>
                                  <template v-else>
                                    <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                  </template>
                                </template>
                              </template>
                            </template>

                          </b-col>
                        </template>
                      </b-row>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>
          <!-- 4column_textview_linkclickfordetails -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '3'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <b-row>
                        <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                          <b-col sm="12" md="12" lg="3" style="text-align: center; padding: 10px;">
                            <div v-if="StoreWebsiteEdit === 'yes'">
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                                  <i class="fa fa-copy"></i>
                                </b-button>
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                                  <i class="fa fa-arrow-up"></i>
                                </b-button>
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                                  <i class="fa fa-arrow-down"></i>
                                </b-button>
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                                  <i class="fa fa-arrow-circle-up"></i>
                                </b-button>
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                                  <i class="fa fa-arrow-circle-down"></i>
                                </b-button>
                                <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                                  <i class="fa fa-trash"></i>
                                </b-button>
                              </div>
                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <h1> {{ page_info_detail['10']['lang'][StoreLang] }} </h1>
                                </template>
                              </template>
                            </template>

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-form-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-form-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                </template>
                              </template>
                            </template>

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <input v-if="page_info_detail['13']" type="text" class="form-control" v-model="page_info_detail['13']['lang'][lang.label]">
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <div>
                                <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                  <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                    {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                  </a>
                                </template>
                              </div>
                            </template>
                          </b-col>
                        </template>
                      </b-row>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>



          <!-- 2column_lefttextview_rightimage -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '4'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <div v-if="StoreWebsiteEdit === 'yes'">
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                                <i class="fa fa-copy"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                                <i class="fa fa-arrow-up"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                                <i class="fa fa-arrow-down"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                                <i class="fa fa-arrow-circle-up"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                                <i class="fa fa-arrow-circle-down"></i>
                              </b-button>
                              <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                                <i class="fa fa-trash"></i>
                              </b-button>
                            </div>
                        <b-row style="margin-top: 10px; margin-bottom: 10px;">
                          <b-col sm="12" md="12" lg="6">

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <h3>{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                                </template>
                              </template>
                            </template>

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                </template>
                              </template>
                            </template>

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <b-textarea v-if="page_info_detail['13']" v-model="page_info_detail['13']['lang'][lang.label]"></b-textarea>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <div style="margin-top: 30px;">
                                <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                  <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                    {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                  </a>
                                </template>
                              </div>
                            </template>

                          </b-col>
                          <b-col sm="12" md="12" lg="6">

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                                  </b-col>
                                </b-row>
                            </template>
                            <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="width: 100%; border-radius: 10px 10px;">

                          </b-col>
                        </b-row>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>


          <!-- 2column_righttextview_leftimage -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '5'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <div v-if="StoreWebsiteEdit === 'yes'">
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-copy"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                            <i class="fa fa-arrow-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                            <i class="fa fa-arrow-down"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                            <i class="fa fa-arrow-circle-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                            <i class="fa fa-arrow-circle-down"></i>
                          </b-button>
                          <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </div>
                        <template v-if="StoreDevice.isMobile">
                          <b-row style="margin-top: 10px; margin-bottom: 10px;">
                            <b-col sm="12" md="12" lg="6">

                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                  <b-row>
                                    <b-col cols="12">
                                      <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                      <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                    </b-col>
                                  </b-row>
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                        <template v-if="d_inputEditorMode == 'text'">
                                          <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                        </template>
                                        <template v-else-if="d_inputEditorMode == 'html'">
                                          <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                        </template>
                                      </template>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                  <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                    <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                  </template>
                                  <template v-else>
                                    <h3>{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                                  </template>
                                </template>
                              </template>

                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                  <b-row>
                                    <b-col cols="12">
                                      <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                      <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                    </b-col>
                                  </b-row>
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                        <template v-if="d_inputEditorMode == 'text'">
                                          <b-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                        </template>
                                        <template v-else-if="d_inputEditorMode == 'html'">
                                          <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                        </template>
                                      </template>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                  <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                    <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                  </template>
                                  <template v-else>
                                    <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                  </template>
                                </template>
                              </template>

                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <b-textarea v-if="page_info_detail['13']" v-model="page_info_detail['13']['lang'][lang.label]"></b-textarea>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <div style="margin-top: 30px;">
                                  <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                    <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                      {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                    </a>
                                  </template>
                                </div>
                              </template>
                              
                            </b-col>
                            <b-col sm="12" md="12" lg="6">



                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                                    </b-col>
                                  </b-row>
                              </template>
                              <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="width: 100%; border-radius: 10px 10px;">


                            </b-col>
                          </b-row>                          
                        </template>
                        <template v-else>
                          <b-row style="margin-top: 10px; margin-bottom: 10px;">
                            <b-col sm="12" md="12" lg="6">

                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                                    </b-col>
                                  </b-row>
                              </template>
                              <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="width: 100%; border-radius: 10px 10px;">

                            </b-col>
                            <b-col sm="12" md="12" lg="6">

                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                  <b-row>
                                    <b-col cols="12">
                                      <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                      <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                    </b-col>
                                  </b-row>
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                        <template v-if="d_inputEditorMode == 'text'">
                                          <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                        </template>
                                        <template v-else-if="d_inputEditorMode == 'html'">
                                          <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                        </template>
                                      </template>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                  <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                    <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                  </template>
                                  <template v-else>
                                    <h3>{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                                  </template>
                                </template>
                              </template>

                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                  <b-row>
                                    <b-col cols="12">
                                      <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                      <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                    </b-col>
                                  </b-row>
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                        <template v-if="d_inputEditorMode == 'text'">
                                          <b-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                        </template>
                                        <template v-else-if="d_inputEditorMode == 'html'">
                                          <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                        </template>
                                      </template>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                  <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                    <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                  </template>
                                  <template v-else>
                                    <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                  </template>
                                </template>
                              </template>

                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <b-textarea v-if="page_info_detail['13']" v-model="page_info_detail['13']['lang'][lang.label]"></b-textarea>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <div style="margin-top: 30px;">
                                  <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                    <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                      {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                    </a>
                                  </template>
                                </div>
                              </template>
                              
                            </b-col>
                          </b-row>
                        </template>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>


          <!-- 1column_lefttextview_backgroundimage -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '6'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <div v-if="StoreWebsiteEdit === 'yes'">
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-copy"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                            <i class="fa fa-arrow-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                            <i class="fa fa-arrow-down"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                            <i class="fa fa-arrow-circle-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                            <i class="fa fa-arrow-circle-down"></i>
                          </b-button>
                          <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </div>

                        <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                          <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                            {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                          </i>
                        </div>
                        <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                              <b-col sm="12" lg="2">
                                {{ lang.label }}
                              </b-col>
                              <b-col sm="12" lg="10">
                                <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                              </b-col>
                            </b-row>
                        </template>

                        <b-row v-if="f_getImageLink(page_info_detail)" :style="'background-image: url(' + f_getImageLink(page_info_detail) + '); background-size: cover; background-repeat: no-repeat; width: 100%; padding: 30px; border-radius: 10px 10px;'">
                          <b-col sm="12" md="12" lg="6">

                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                  <b-row>
                                    <b-col cols="12">
                                      <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                      <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                    </b-col>
                                  </b-row>
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                        <template v-if="d_inputEditorMode == 'text'">
                                          <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                        </template>
                                        <template v-else-if="d_inputEditorMode == 'html'">
                                          <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                        </template>
                                      </template>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                  <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                    <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                  </template>
                                  <template v-else>
                                    <h1>{{ page_info_detail['10']['lang'][StoreLang] }}</h1>
                                  </template>
                                </template>
                              </template>

                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                  <b-row>
                                    <b-col cols="12">
                                      <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                      <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                    </b-col>
                                  </b-row>
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                        <template v-if="d_inputEditorMode == 'text'">
                                          <b-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                        </template>
                                        <template v-else-if="d_inputEditorMode == 'html'">
                                          <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                        </template>
                                      </template>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                  <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                    <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                  </template>
                                  <template v-else>
                                    <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                  </template>
                                </template>
                              </template>

                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <b-textarea v-if="page_info_detail['13']" v-model="page_info_detail['13']['lang'][lang.label]"></b-textarea>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <div style="margin-top: 30px;">
                                  <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                    <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                      {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                    </a>
                                  </template>
                                </div>
                              </template>
                            
                          </b-col>
                          <b-col sm="12" md="12" lg="6"></b-col>
                        </b-row>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>


          <!-- 1column_righttextview_backgroundimage -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '7'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <div v-if="StoreWebsiteEdit === 'yes'">
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-copy"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                            <i class="fa fa-arrow-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                            <i class="fa fa-arrow-down"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                            <i class="fa fa-arrow-circle-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                            <i class="fa fa-arrow-circle-down"></i>
                          </b-button>
                          <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </div>

                        <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                          <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                            {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                          </i>
                        </div>
                        <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                              <b-col sm="12" lg="2">
                                {{ lang.label }}
                              </b-col>
                              <b-col sm="12" lg="10">
                                <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                              </b-col>
                            </b-row>
                        </template>

                        <b-row v-if="f_getImageLink(page_info_detail)" :style="'background-image: url(' + f_getImageLink(page_info_detail) + '); background-size: cover; background-repeat: no-repeat; width: 100%; padding: 30px; border-radius: 10px 10px;'">
                          <b-col sm="12" md="12" lg="6">
                          </b-col>
                          <b-col sm="12" md="12" lg="6">


                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                  <b-row>
                                    <b-col cols="12">
                                      <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                      <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                    </b-col>
                                  </b-row>
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                        <template v-if="d_inputEditorMode == 'text'">
                                          <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                        </template>
                                        <template v-else-if="d_inputEditorMode == 'html'">
                                          <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                        </template>
                                      </template>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                  <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                    <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                  </template>
                                  <template v-else>
                                    <h1>{{ page_info_detail['10']['lang'][StoreLang] }}</h1>
                                  </template>
                                </template>
                              </template>

                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                  <b-row>
                                    <b-col cols="12">
                                      <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                      <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                    </b-col>
                                  </b-row>
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                        <template v-if="d_inputEditorMode == 'text'">
                                          <b-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                        </template>
                                        <template v-else-if="d_inputEditorMode == 'html'">
                                          <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                        </template>
                                      </template>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                  <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                    <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                  </template>
                                  <template v-else>
                                    <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                  </template>
                                </template>
                              </template>

                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <b-textarea v-if="page_info_detail['13']" v-model="page_info_detail['13']['lang'][lang.label]"></b-textarea>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <div style="margin-top: 30px;">
                                  <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                    <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                      {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                    </a>
                                  </template>
                                </div>
                              </template>
                            
                            
                          </b-col>
                        </b-row>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>



          <!-- 1column_centertextview_noimage -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '8'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <div v-if="StoreWebsiteEdit === 'yes'">
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-copy"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                            <i class="fa fa-arrow-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                            <i class="fa fa-arrow-down"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                            <i class="fa fa-arrow-circle-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                            <i class="fa fa-arrow-circle-down"></i>
                          </b-button>
                          <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </div>
                        <b-row style="text-align: center; padding: 20px;">
                          <b-col sm="12" md="12" lg="1"></b-col>
                          <b-col sm="12" md="12" lg="10">

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <h1 style="font-weight: 700;">{{ page_info_detail['10']['lang'][StoreLang] }}</h1>
                                </template>
                              </template>
                            </template>

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                </template>
                              </template>
                            </template>

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <b-textarea v-if="page_info_detail['13']" v-model="page_info_detail['13']['lang'][lang.label]"></b-textarea>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <div style="margin-top: 30px;">
                                <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                  <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                    {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                  </a>
                                </template>
                              </div>
                            </template>

                          </b-col>
                          <b-col sm="12" md="12" lg="1"></b-col>
                        </b-row>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>



          <!-- 2column_righttextview_leftimageicon -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '9'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <b-row>
                        <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                          <b-col sm="12" md="12" lg="6" style="padding: 10px;">
                            <div v-if="StoreWebsiteEdit === 'yes'">
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                                  <i class="fa fa-copy"></i>
                                </b-button>
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                                  <i class="fa fa-arrow-up"></i>
                                </b-button>
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                                  <i class="fa fa-arrow-down"></i>
                                </b-button>
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                                  <i class="fa fa-arrow-circle-up"></i>
                                </b-button>
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                                  <i class="fa fa-arrow-circle-down"></i>
                                </b-button>
                                <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                                  <i class="fa fa-trash"></i>
                                </b-button>
                              </div>
                             <b-card class="component_9">
                                <b-row>
                                  <b-col sm="12" md="12" lg="3">
                                    <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                      <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                                        {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                                      </i>
                                    </div>
                                    <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                                        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                          <b-col sm="12" lg="2">
                                            {{ lang.label }}
                                          </b-col>
                                          <b-col sm="12" lg="10">
                                            <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                                          </b-col>
                                        </b-row>
                                    </template>
                                    <div v-if="f_getImageLink(page_info_detail)" style="display: flex; align-items: center; height: 100%;">
                                      <img :src="f_getImageLink(page_info_detail)" style="height: 40px; width: 40px;">
                                    </div>
                                  </b-col>
                                  <b-col sm="12" md="12" lg="9">

                                    <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                      <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                        {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                                      </i>
                                    </div>
                                    <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                        <b-row>
                                          <b-col cols="12">
                                            <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                            <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                          </b-col>
                                        </b-row>
                                        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                          <b-col sm="12" lg="2">
                                            {{ lang.label }}
                                          </b-col>
                                          <b-col sm="12" lg="10">
                                            <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                              <template v-if="d_inputEditorMode == 'text'">
                                                <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                              </template>
                                              <template v-else-if="d_inputEditorMode == 'html'">
                                                <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                              </template>
                                            </template>
                                          </b-col>
                                        </b-row>
                                    </template>
                                    <template v-else>
                                      <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                        <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                          <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                        </template>
                                        <template v-else>
                                          <h3>{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                                        </template>
                                      </template>
                                    </template>

                                    <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                      <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                        {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                                      </i>
                                    </div>
                                    <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                        <b-row>
                                          <b-col cols="12">
                                            <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                            <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                          </b-col>
                                        </b-row>
                                        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                          <b-col sm="12" lg="2">
                                            {{ lang.label }}
                                          </b-col>
                                          <b-col sm="12" lg="10">
                                            <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                              <template v-if="d_inputEditorMode == 'text'">
                                                <b-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                              </template>
                                              <template v-else-if="d_inputEditorMode == 'html'">
                                                <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                              </template>
                                            </template>
                                          </b-col>
                                        </b-row>
                                    </template>
                                    <template v-else>
                                      <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                        <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                          <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                        </template>
                                        <template v-else>
                                          <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                        </template>
                                      </template>
                                    </template>

                                    <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                      <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                        {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                                      </i>
                                    </div>
                                    <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                          <b-col sm="12" lg="2">
                                            {{ lang.label }}
                                          </b-col>
                                          <b-col sm="12" lg="10">
                                            <b-textarea v-if="page_info_detail['13']" v-model="page_info_detail['13']['lang'][lang.label]"></b-textarea>
                                          </b-col>
                                        </b-row>
                                    </template>
                                    <template v-else>
                                      <div>
                                        <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                          <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                            {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                          </a>
                                        </template>
                                      </div>
                                    </template>
                                    
                                  </b-col>
                                </b-row>
                             </b-card>
                          </b-col>
                        </template>
                      </b-row>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>



          <!-- 3column_belowtextview_leftimageiconofcaption -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '10'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <b-row>
                        <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                          <b-col sm="12" md="12" lg="4" style="min-height: 300px; padding-bottom: 5px; margin-bottom: 10px;">
                            <div v-if="StoreWebsiteEdit === 'yes'">
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                                <i class="fa fa-copy"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                                <i class="fa fa-arrow-up"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                                <i class="fa fa-arrow-down"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                                <i class="fa fa-arrow-circle-up"></i>
                              </b-button>
                              <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                                <i class="fa fa-arrow-circle-down"></i>
                              </b-button>
                              <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                                <i class="fa fa-trash"></i>
                              </b-button>
                            </div>
                             <b-card class="component_10">
                                <b-row>
                                  <b-col sm="12" md="12" lg="2">
                                    <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                      <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                                        {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                                      </i>
                                    </div>
                                    <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                                        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                          <b-col sm="12" lg="2">
                                            {{ lang.label }}
                                          </b-col>
                                          <b-col sm="12" lg="10">
                                            <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                                          </b-col>
                                        </b-row>
                                    </template>
                                    <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="height: 30px; width: 30px;">
                                  </b-col>
                                  <b-col sm="12" md="12" lg="10">
                                    <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                      <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                        {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                                      </i>
                                    </div>
                                    <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                        <b-row>
                                          <b-col cols="12">
                                            <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                            <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                          </b-col>
                                        </b-row>
                                        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                          <b-col sm="12" lg="2">
                                            {{ lang.label }}
                                          </b-col>
                                          <b-col sm="12" lg="10">
                                            <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                              <template v-if="d_inputEditorMode == 'text'">
                                                <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                              </template>
                                              <template v-else-if="d_inputEditorMode == 'html'">
                                                <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                              </template>
                                            </template>
                                          </b-col>
                                        </b-row>
                                    </template>
                                    <template v-else>
                                      <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                        <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                          <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                        </template>
                                        <template v-else>
                                          <h3>{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                                        </template>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col sm="12" md="12" lg="12">
                                    <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                      <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                        {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                                      </i>
                                    </div>
                                    <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                        <b-row>
                                          <b-col cols="12">
                                            <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                            <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                          </b-col>
                                        </b-row>
                                        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                          <b-col sm="12" lg="2">
                                            {{ lang.label }}
                                          </b-col>
                                          <b-col sm="12" lg="10">
                                            <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                              <template v-if="d_inputEditorMode == 'text'">
                                                <b-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                              </template>
                                              <template v-else-if="d_inputEditorMode == 'html'">
                                                <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                              </template>
                                            </template>
                                          </b-col>
                                        </b-row>
                                    </template>
                                    <template v-else>
                                      <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                        <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                          <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                        </template>
                                        <template v-else>
                                          <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                        </template>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                                <b-row style="margin-top: 20px;">
                                  <b-col sm="12" md="12" lg="12">
                                    <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                      <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                        {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                                      </i>
                                    </div>
                                    <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                          <b-col sm="12" lg="2">
                                            {{ lang.label }}
                                          </b-col>
                                          <b-col sm="12" lg="10">
                                            <b-textarea v-if="page_info_detail['13']" v-model="page_info_detail['13']['lang'][lang.label]"></b-textarea>
                                          </b-col>
                                        </b-row>
                                    </template>
                                    <template v-else>
                                      <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                        <div>
                                          <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                            {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                          </a>
                                          </div>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                             </b-card>
                          </b-col>
                        </template>
                      </b-row>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>



          <!-- 3column_belowtextview_topimageofcaption -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '11'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <b-row>
                        <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                          <b-col sm="12" md="12" lg="4" style="min-height: 300px;">
                            <div v-if="StoreWebsiteEdit === 'yes'">
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                                  <i class="fa fa-copy"></i>
                                </b-button>
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                                  <i class="fa fa-arrow-up"></i>
                                </b-button>
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                                  <i class="fa fa-arrow-down"></i>
                                </b-button>
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                                  <i class="fa fa-arrow-circle-up"></i>
                                </b-button>
                                <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                                  <i class="fa fa-arrow-circle-down"></i>
                                </b-button>
                                <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                                  <i class="fa fa-trash"></i>
                                </b-button>
                              </div>
                             <div style="height: 100%; background-color: rgba(252, 252, 252, 1.0); border-radius: 20px 20px 20px 20px; box-shadow: rgb(245 245 245) 0px 0px 11px 0px; border: none; padding: 0px;">
                                <b-row style="min-height: 150px; margin: 0px;">
                                  <b-col sm="12" md="12" lg="12" style="padding: 0px;">


                                    <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                      <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                                        {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                                      </i>
                                    </div>
                                    <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                                        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                          <b-col sm="12" lg="2">
                                            {{ lang.label }}
                                          </b-col>
                                          <b-col sm="12" lg="10">
                                            <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                                          </b-col>
                                        </b-row>
                                    </template>
                                    <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="height: 100%; width: 100%; border-radius: 20px 20px 0px 0px;">
                                  </b-col>
                                </b-row>
                                <b-row style="min-height: 30px; padding: 15px;">
                                  <b-col sm="12" md="12" lg="12">
                                    <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                      <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                        {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                                      </i>
                                    </div>
                                    <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                        <b-row>
                                          <b-col cols="12">
                                            <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                            <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                          </b-col>
                                        </b-row>
                                        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                          <b-col sm="12" lg="2">
                                            {{ lang.label }}
                                          </b-col>
                                          <b-col sm="12" lg="10">
                                            <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                              <template v-if="d_inputEditorMode == 'text'">
                                                <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                              </template>
                                              <template v-else-if="d_inputEditorMode == 'html'">
                                                <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                              </template>
                                            </template>
                                          </b-col>
                                        </b-row>
                                    </template>
                                    <template v-else>
                                      <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                        <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                          <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                        </template>
                                        <template v-else>
                                          <h3>{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                                        </template>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                                <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                  <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                    {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                                  </i>
                                </div>
                                <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                    <b-row>
                                      <b-col cols="12">
                                        <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                        <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                      </b-col>
                                    </b-row>
                                    <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                      <b-col sm="12" lg="2">
                                        {{ lang.label }}
                                      </b-col>
                                      <b-col sm="12" lg="10">
                                        <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                          <template v-if="d_inputEditorMode == 'text'">
                                            <b-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                          </template>
                                          <template v-else-if="d_inputEditorMode == 'html'">
                                            <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                          </template>
                                        </template>
                                      </b-col>
                                    </b-row>
                                </template>
                                <template v-else>
                                  <b-row v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]" style="min-height: 30px; padding: 15px;">
                                    <b-col sm="12" md="12" lg="12">
                                      <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                        <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                      </template>
                                      <template v-else>
                                        <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                      </template>
                                    </b-col>
                                  </b-row>
                                </template>

                            
                                <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                  <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                    {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                                  </i>
                                </div>
                                <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                    <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                      <b-col sm="12" lg="2">
                                        {{ lang.label }}
                                      </b-col>
                                      <b-col sm="12" lg="10">
                                        <b-textarea v-if="page_info_detail['13']" v-model="page_info_detail['13']['lang'][lang.label]"></b-textarea>
                                      </b-col>
                                    </b-row>
                                </template>
                                <template v-else>
                                  <b-row v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]" style="margin-top: 30px; padding: 15px;">
                                    <b-col sm="12" md="12" lg="12">
                                        <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                          {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                        </a>
                                    </b-col>
                                  </b-row>
                                </template>

                             </div>
                          </b-col>
                        </template>
                      </b-row>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>



          <!-- 1column_centertextview_backgroundfullwidthimage_linkclickfordetailsastext -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '12'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="12">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <div v-if="StoreWebsiteEdit === 'yes'">
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-copy"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                            <i class="fa fa-arrow-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                            <i class="fa fa-arrow-down"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                            <i class="fa fa-arrow-circle-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                            <i class="fa fa-arrow-circle-down"></i>
                          </b-button>
                          <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </div>

                        <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                          <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                            {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                          </i>
                        </div>
                        <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                              <b-col sm="12" lg="2">
                                {{ lang.label }}
                              </b-col>
                              <b-col sm="12" lg="10">
                                <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                              </b-col>
                            </b-row>
                        </template>

                        <b-row v-if="f_getImageLink(page_info_detail)" :style="'background-image: url(' + f_getImageLink(page_info_detail) + '); background-size: cover; background-repeat: no-repeat; padding: 30px; width 100%; text-align: center;'">
                          <b-col sm="12" md="12" lg="12" style="padding: 50px;">
                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="page_info_detail['10']">
                                <template v-if="page_info_detail['10'].lang[StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['10'].lang[StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <h1 style="margin: 10px;"> {{ page_info_detail['10']['lang'][StoreLang] }} </h1>
                                </template>
                              </template>
                            </template>
                            <div style="height: 30px;"></div>

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-form-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-form-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <h4 style="margin: 10px;">{{ page_info_detail['26']['lang'][StoreLang] }}</h4>
                                </template>
                              </template>
                            </template>

                            <div style="margin-top: 30px;">
                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <input v-if="page_info_detail['13']" type="text" class="form-control" v-model="page_info_detail['13']['lang'][lang.label]">
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                  <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                    {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                  </a>
                                </template>
                              </template>
                            </div>
                          </b-col>
                        </b-row>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>



          <!-- 1column_centertextview_backgroundfullwidthimage_linkclickfordetailsasbutton -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '13'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="12">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <div v-if="StoreWebsiteEdit === 'yes'">
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-copy"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                            <i class="fa fa-arrow-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                            <i class="fa fa-arrow-down"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                            <i class="fa fa-arrow-circle-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                            <i class="fa fa-arrow-circle-down"></i>
                          </b-button>
                          <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </div>


                          <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                            <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                              {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                            </i>
                          </div>
                          <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="2">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="10">
                                  <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                                </b-col>
                              </b-row>
                          </template>

                        <b-row v-if="f_getImageLink(page_info_detail)" :style="'background-image: url(' + f_getImageLink(page_info_detail) + '); background-size: cover; background-repeat: no-repeat; padding: 30px; height: 350px; text-align: center;'">
                          <b-col sm="12" md="12" lg="12" style="padding: 50px;">
                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                </template>
                                <template v-else>
                                    <h1>{{ page_info_detail['10']['lang'][StoreLang] }}</h1>
                                </template>
                              </template>
                            </template>


                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-form-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-form-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                </template>
                              </template>
                            </template>


                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <input v-if="page_info_detail['13']" type="text" class="form-control" v-model="page_info_detail['13']['lang'][lang.label]">
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <div style="margin-top: 30px;">
                                  <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                    <b-button variant="primary" @click="f_goToThisUrl(page_info_detail['13'].lang[StoreLang])">
                                      <span style="margin-right: 20px;">
                                        {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                      </span>
                                      <i class="fa fa-long-arrow-right"></i>
                                    </b-button>
                                  </template>
                                </div>
                              </template>
                            
                            
                          </b-col>
                        </b-row>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>


          <!-- 2columnimagecolumnsmaller_lefttextview_rightimage -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '14'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <div v-if="StoreWebsiteEdit === 'yes'">
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-copy"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                            <i class="fa fa-arrow-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                            <i class="fa fa-arrow-down"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                            <i class="fa fa-arrow-circle-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                            <i class="fa fa-arrow-circle-down"></i>
                          </b-button>
                          <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </div>
                        <b-row style="margin-top: 10px; margin-bottom: 10px;">
                          <b-col sm="12" md="12" lg="9">
                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                                <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                  <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                    <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                  </template>
                                  <template v-else>
                                    <h3>{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                                  </template>
                                </template>
                            </template>

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-form-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-form-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                </template>
                              </template>
                            </template>


                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <input v-if="page_info_detail['13']" type="text" class="form-control" v-model="page_info_detail['13']['lang'][lang.label]">
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <div style="margin-top: 30px;">
                                  <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                    <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                      {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                    </a>
                                  </template>
                                </div>
                              </template>

                          </b-col>
                          <b-col sm="12" md="12" lg="3">

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                                  </b-col>
                                </b-row>
                            </template>
                            <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="width: 100%; border-radius: 10px 10px;">
                          </b-col>
                        </b-row>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>

          <!-- 2columnimagecolumnsmaller_righttextview_leftimage -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '15'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <template v-if="page_info['9'] && page_info['9']['list'] && page_info['9']['list'].length > 0">
                      <template v-for="(page_info_detail, page_info_detail_index) in page_info['9']['list']">
                        <div v-if="StoreWebsiteEdit === 'yes'">
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-primary" @click="f_copyPageInfo(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-copy"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'up')">
                            <i class="fa fa-arrow-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'down')">
                            <i class="fa fa-arrow-down"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'top')">
                            <i class="fa fa-arrow-circle-up"></i>
                          </b-button>
                          <b-button size="sm" style="margin-right: 1px;" variant="outline-secondary" @click="f_listParamOrder(page_info['9']['list'], page_info_detail_index, 'bottom')">
                            <i class="fa fa-arrow-circle-down"></i>
                          </b-button>
                          <b-button size="sm" variant="outline-danger" @click="f_deleteListItem(page_info['9']['list'], page_info_detail_index)">
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </div>
                        <template v-if="StoreDevice.isMobile">
                          <b-row style="margin-top: 10px; margin-bottom: 10px;">
                            <b-col sm="12" md="12" lg="9">

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                                <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                  <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                    <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                  </template>
                                  <template v-else>
                                    <h3>{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                                  </template>
                                </template>
                            </template>

                            <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                              <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                              </i>
                            </div>
                            <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                <b-row>
                                  <b-col cols="12">
                                    <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                    <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                  </b-col>
                                </b-row>
                                <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                  <b-col sm="12" lg="2">
                                    {{ lang.label }}
                                  </b-col>
                                  <b-col sm="12" lg="10">
                                    <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                      <template v-if="d_inputEditorMode == 'text'">
                                        <b-form-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-form-textarea>
                                      </template>
                                      <template v-else-if="d_inputEditorMode == 'html'">
                                        <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                            </template>
                            <template v-else>
                              <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                  <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                </template>
                                <template v-else>
                                  <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                </template>
                              </template>
                            </template>


                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <input v-if="page_info_detail['13']" type="text" class="form-control" v-model="page_info_detail['13']['lang'][lang.label]">
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <div style="margin-top: 30px;">
                                  <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                    <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                      {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                    </a>
                                  </template>
                                </div>
                              </template>
                              
                            </b-col>
                            <b-col sm="12" md="12" lg="3">
                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                                    </b-col>
                                  </b-row>
                              </template>
                              <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="width: 100%; border-radius: 10px 10px;">
                            </b-col>
                          </b-row>
                        </template>
                        <template v-else>
                          <b-row style="margin-top: 10px; margin-bottom: 10px;">
                            <b-col sm="12" md="12" lg="3">

                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '19', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_image'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '19'">
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <b-textarea v-if="page_info_detail['19']" v-model="page_info_detail['19']['lang'][lang.label]"></b-textarea>
                                    </b-col>
                                  </b-row>
                              </template>
                              <img v-if="f_getImageLink(page_info_detail)" :src="f_getImageLink(page_info_detail)" style="width: 100%; border-radius: 10px 10px;">

                            </b-col>
                            <b-col sm="12" md="12" lg="9">
                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '10', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_caption'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '10'">
                                  <b-row>
                                    <b-col cols="12">
                                      <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                      <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '10')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                    </b-col>
                                  </b-row>
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <template v-if="page_info_detail['10'] && page_info_detail['10']['lang']">
                                        <template v-if="d_inputEditorMode == 'text'">
                                          <b-textarea v-model="page_info_detail['10']['lang'][lang.label]" style="height: 300px;"></b-textarea>
                                        </template>
                                        <template v-else-if="d_inputEditorMode == 'html'">
                                          <html-editor :key="lang_index" :p_html_text="page_info_detail['10']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                        </template>
                                      </template>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <template v-if="page_info_detail['10'] && page_info_detail['10']['lang'][StoreLang]">
                                  <template v-if="page_info_detail['10']['lang'][StoreLang].indexOf('</') !== -1">
                                    <div v-html="page_info_detail['10']['lang'][StoreLang]"></div>
                                  </template>
                                  <template v-else>
                                    <h3>{{ page_info_detail['10']['lang'][StoreLang] }}</h3>
                                  </template>
                                </template>
                              </template>

                              <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '26', page_info_detail, true)">
                                  {{ StoreLangTranslation.data['detail_explanation'][StoreLang] }}
                                </i>
                              </div>
                              <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '26'">
                                  <b-row>
                                    <b-col cols="12">
                                      <b-button :class="{ active: d_inputEditorMode === 'html' }"  variant="outline-secondary" @click="f_changeInputEditorMode('html')" style="font-size: 14px; margin-right: 2px;">{{ StoreLangTranslation.data['html_editor'][StoreLang] }}</b-button>
                                      <b-button :class="{ active: d_inputEditorMode === 'text' }"  variant="outline-secondary" @click="f_changeInputEditorMode('text', page_info_detail, '26')" style="font-size: 14px;">{{ StoreLangTranslation.data['text_editor'][StoreLang] }}</b-button>
                                    </b-col>
                                  </b-row>
                                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                    <b-col sm="12" lg="2">
                                      {{ lang.label }}
                                    </b-col>
                                    <b-col sm="12" lg="10">
                                      <template v-if="page_info_detail['26'] && page_info_detail['26']['lang']">
                                        <template v-if="d_inputEditorMode == 'text'">
                                          <b-form-textarea v-model="page_info_detail['26']['lang'][lang.label]" style="height: 300px;"></b-form-textarea>
                                        </template>
                                        <template v-else-if="d_inputEditorMode == 'html'">
                                          <html-editor :key="lang_index" :p_html_text="page_info_detail['26']['lang'][lang.label]" :p_htmlElementId="'website_html_edit_' + lang.label"></html-editor>
                                        </template>
                                      </template>
                                    </b-col>
                                  </b-row>
                              </template>
                              <template v-else>
                                <template v-if="page_info_detail['26'] && page_info_detail['26'].lang && page_info_detail['26'].lang[StoreLang]">
                                  <template v-if="page_info_detail['26'].lang[StoreLang].indexOf('</') !== -1">
                                    <div v-html="page_info_detail['26'].lang[StoreLang]"></div>
                                  </template>
                                  <template v-else>
                                    <small>{{ page_info_detail['26']['lang'][StoreLang] }}</small>
                                  </template>
                                </template>
                              </template>


                                <div v-if="StoreWebsiteEdit === 'yes'" style="text-align: center;">
                                  <i class="fa fa-edit" style="font-size:  12px; cursor: pointer; color: red;" @click="f_editparameterSelection(page_info_index, page_info_detail_index, '13', page_info_detail, true)">
                                    {{ StoreLangTranslation.data['detail_link'][StoreLang] }}
                                  </i>
                                </div>
                                <template v-if="page_info_index === d_editPageInfoIndex && page_info_detail_index === d_editPageInfoDetailIndex && StoreWebsiteEdit === 'yes' && d_editParameter === '13'">
                                    <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                      <b-col sm="12" lg="2">
                                        {{ lang.label }}
                                      </b-col>
                                      <b-col sm="12" lg="10">
                                        <input v-if="page_info_detail['13']" type="text" class="form-control" v-model="page_info_detail['13']['lang'][lang.label]">
                                      </b-col>
                                    </b-row>
                                </template>
                                <template v-else>
                                  <div style="margin-top: 30px;">
                                    <template v-if="page_info_detail['13'] && page_info_detail['13'].lang && page_info_detail['13'].lang[StoreLang]">
                                      <a :href="f_createHrefLink(page_info_detail['13'].lang[StoreLang])" target="_blank">
                                        {{ StoreLangTranslation.data['click_for_details'][StoreLang] }}
                                      </a>
                                    </template>
                                  </div>
                                </template>
                                
                              </b-col>
                          </b-row>
                        </template>
                        <div v-if="page_info_detail_index !== page_info['9']['list'].length -1" style="width: 100%; height: 50px;"></div>
                      </template>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>

          <!-- contact form -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '16'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <support-screen :p_supportData="d_supportData"></support-screen>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>



          <!-- wai search -->
          <template v-if="page_info['27'] && page_info['27'].val && page_info['27'].val.value === '17'">
            <div class="wrapper">
              <div class="animated fadeIn">
                <b-row style="margin-top: 30px; margin-bottom: 30px;">
                  <b-col sm="12" md="12" lg="2"></b-col>
                  <b-col sm="12" md="12" lg="8">
                    <!-- <support-screen :p_supportData="d_supportData"></support-screen> -->
                    <template v-if="page_info['33'] && page_info['33'].val && page_info['33'].val.value">
                      <wai-project-search :p_searchType="page_info['33'].val.value"></wai-project-search>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2"></b-col>
                </b-row>
              </div>
            </div>
            <!-- <div style="width: 100%; height: 50px;"></div> -->
          </template>


        <div v-if="StoreWebsiteEdit === 'yes'" style="margin: 20px;">
          <b-row>
            <b-col sm="12" lg="2"> </b-col>
            <b-col sm="12" lg="4"></b-col>
            <b-col sm="12" lg="6" style="text-align: right;">
              <b-button size="sm" style="margin-right: 5px;" variant="outline-primary" @click="f_newPageContent(page_info)">
                <i class="fa fa-plus"></i> {{ StoreLangTranslation.data['new_page_content'][StoreLang] }}
              </b-button>
            </b-col>
          </b-row>
        </div>

      </template>

    </template>


    <div v-if="StoreWebsiteEdit === 'yes'" style="margin: 20px;">
      <b-row>
        <b-col sm="12" lg="2"> </b-col>
        <b-col sm="12" lg="4"></b-col>
        <b-col sm="12" lg="6" style="text-align: right;">
          <b-button size="lg" style="margin-right: 5px;" variant="outline-success" @click="f_newPageItem()">
            <i class="fa fa-plus"></i> {{ StoreLangTranslation.data['new_page_component'][StoreLang] }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-modal v-if="d_imageUploadModal" id="d_imageUploadModal_show" v-model="d_imageUploadModal" scrollable hide-footer :title="StoreLangTranslation.data['image_upload'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <p>
        <b-row>
          <b-col sm="12" lg="12">
            <b-form-file
                @input="f_addImageToDocuments()"
                style="height: 100px; border: dotted 2px #25bcff; background: #daf9ff;"
                v-model="d_tmpFile"
                :accept="f_createAcceptedDocumentTypes()"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
            >
              <i class="fa fa-plus"></i>
            </b-form-file>
          </b-col>
        </b-row>
      </p>
      <p>
        <template v-for="(doc, doc_index) in d_systemDocumentList">
          <b-row>
            <b-col sm="12" lg="12">
              {{ doc }}
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="10">
              <img v-if="!d_isImageDocLoading" :src="doc" width="300">
            </b-col>
            <b-col sm="12" lg="2">
              <b-button size="sm" style="margin-right: 5px; margin-bottom: 5px;" variant="outline-success" @click="f_selectImage(doc)">
                <i class="fa fa-plus"></i> {{ StoreLangTranslation.data['select_image'][StoreLang] }}
              </b-button>
              <b-button size="sm" style="margin-right: 5px; margin-bottom: 5px;" variant="outline-warning" @click="f_openUpdateImage(doc)">
                <i class="fa fa-pencil"></i> {{ StoreLangTranslation.data['update_image'][StoreLang] }}
              </b-button>
              <b-button size="sm" style="margin-right: 5px;" variant="outline-danger" @click="f_deleteImage(doc)">
                <i class="fa fa-trash"></i> {{ StoreLangTranslation.data['delete_image'][StoreLang] }}
              </b-button>
            </b-col>
          </b-row>
          <b-row v-if="d_updateImage === doc">
              <b-col sm="12" lg="12">
                <b-form-file
                    @input="f_addImageToDocuments(doc)"
                    style="height: 100px; border: dotted 2px #25bcff; background: #daf9ff;"
                    v-model="d_tmpFile"
                    :accept="f_createAcceptedDocumentTypes()"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                >
                  <i class="fa fa-plus"></i>
                </b-form-file>
              </b-col>
          </b-row>
          <hr>
        </template>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { ClsWdm } from '@/wam/modules/wdm';
import { API_BASE_URL } from '@/config';
import moment from "moment";
import {
  default as MachineLearningResult
} from "@/components/widgets/MachineLearningResult";
import {
  default as MachineLearningQuery
} from "@/components/widgets/MachineLearningQuery";
import {
  default as WanalyzerCellCalculate
} from "@/components/widgets/WanalyzerCellCalculate";
import {
  default as WanalyzerDatasetAnalyze
} from "@/components/widgets/WanalyzerDatasetAnalyze";
import {
  default as WanalyzerStatsView
} from "@/components/widgets/WanalyzerStatsView";
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as SupportScreen
} from '@/wam/components/widgets/SupportScreen';
import {
  default as WaiProjectSearch
} from '@/wam/components/widgets/WaiProjectSearch';
import {
  default as HtmlEditor
} from '@/wam/components/widgets/HtmlEditor';
import { mapGetters } from 'vuex';
export default {
  name: 'PageInfoWebsiteUnit',
  components: {
    MachineLearningResult,
    MachineLearningQuery,
    WanalyzerCellCalculate,
    WanalyzerDatasetAnalyze,
    WanalyzerStatsView,
    SupportScreen,
    WaiProjectSearch,
    HtmlEditor,
  },
  computed: {
    ...mapGetters({
      StoreLangTranslation: 'StoreLangTranslation',
      StoreDevice: 'StoreDevice',
      StoreUser: 'StoreUser',
      StoreLoading: 'StoreLoading',
      StoreModal: 'StoreModal',
      StoreLang: 'StoreLang',
      StoreWebsiteEdit: 'StoreWebsiteEdit',
      StoreLangList: 'StoreLangList',
      StoreFrontendSettings: 'StoreFrontendSettings',
    })
  },
  props: {
    p_WebsiteUnitData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      d_supportData: {
        'maximum_characters_name': 100,
        'maximum_characters_note': 500,
        'mail_status': true,
        'phone_status': true,
        'phone': '',
        'mail': '',
        'show': true,
        'edit_eligible': true,
        'operation_name': '',
        'operation_note': '',
        'operation_note_additional': '',
        'maximum_characters': 500,
        'subject': 'General'
      },
      d_showWanalyzerComponents: true,
      d_systemDocumentList: [],
      d_selectedParamlocation: '',
      d_loadingImageUpload: false,
      d_imageUploadModal: false,
      d_tmpFile: null,
      d_slidingInterval: 10000,
      d_slideInfo: 0,
      sliding: null,
      d_machineLearningList: {},
      d_editParameter: '',
      d_editPageInfoIndex: '',
      d_editPageInfoDetailIndex: '',
      d_frdWebUnitWdm: '',
      d_inputEditorMode: 'text',
      d_updateImage: '',
      d_isImageDocLoading: false,
    }
  },
  created: function () {
    ClsWdm.getLayerWdm('frd_webunit').then(resp_wdm_data => { this.d_frdWebUnitWdm = resp_wdm_data; });
    // console.log(this.p_pageInfo);
    // if (this.p_WebsiteUnitData && this.p_WebsiteUnitData.data && this.p_WebsiteUnitData.data['0'] && this.p_WebsiteUnitData.data['0']['7'] && this.p_WebsiteUnitData.data['0']['7']['list'] && this.p_WebsiteUnitData.data['0']['7']['list'].length > 0) {
    //   for (let page_info_index in this.p_WebsiteUnitData.data['0']['7']['list']) {
    //     if (this.p_WebsiteUnitData.data['0']['7']['list'][page_info_index]['9'] && this.p_WebsiteUnitData.data['0']['7']['list'][page_info_index]['9']['list'] && this.p_WebsiteUnitData.data['0']['7']['list'][page_info_index]['9']['list'].length > 0) {
    //       for (let page_info_detail_index in this.p_WebsiteUnitData.data['0']['7']['list'][page_info_index]['9']['list']) {
    //         let page_info_detail_item = this.p_WebsiteUnitData.data['0']['7']['list'][page_info_index]['9']['list'][page_info_detail_index];
    //         if (page_info_detail_item['20'] && page_info_detail_item['20'].val) {
    //           let machine_learning_id = page_info_detail_item['20'].val;
    //           this.f_wanalyzerMachinelearningDataGet(machine_learning_id);
    //         }
    //       }
    //     }
    //   }
    // }
  },
  beforeMount () {},
  mounted: function () {
    // console.log('StoreFrontendSettings : ', this.StoreFrontendSettings);
    // console.log('this.p_WebsiteUnitData.client_id : ', this.p_WebsiteUnitData.client_id);
  },
  methods: {
    f_showWanalyzerComponents: function () {
      this.d_showWanalyzerComponents = false;
      setTimeout(function () {
        this.d_showWanalyzerComponents = true;
        this.$forceUpdate();
      }.bind(this), 1000);
    },
    f_getOptionLabel: function (option_value, options) {
      // console.log('option_value : ', option_value);
      // console.log('options : ', options);
      let option_label = '';
      for (let i in options) {
        if (options[i].value === option_value) {
          option_label = options[i].translation[this.StoreLang];
          break;
        }
      }
      return option_label;
    },
    f_changeInputEditorMode: function(editor_mode, param_location, selected_parameter_id){
      if(editor_mode == "text"){
        for (let lang of this.StoreLangList.data) {
          let div_el = document.getElementById("website_html_edit_" + lang.label);
          param_location[selected_parameter_id]['lang'][lang.label] = div_el.innerHTML;
        }
      }
      setTimeout(function () {
        this.d_inputEditorMode = editor_mode;
      }.bind(this), 50);
    },
    f_editparameterSelection: function (page_info_index, page_info_detail_index, selected_parameter_id, param_location, is_text_type=false) {
      // console.log('page_info_index : ', page_info_index);
      // console.log('param_location  : ', param_location);
      this.d_selectedParamlocation = param_location;
      if (this.d_editParameter === selected_parameter_id && page_info_index === this.d_editPageInfoIndex && page_info_detail_index === this.d_editPageInfoDetailIndex) {
        for (let lang of this.StoreLangList.data) {
          let div_el = document.getElementById("website_html_edit_" + lang.label);
          if (div_el) {
            param_location[selected_parameter_id]['lang'][lang.label] = div_el.innerHTML;
          }
        }
        this.d_editParameter = '';
        this.d_editPageInfoIndex = '';
        this.d_selectedParamlocation = '';
      } else {
        if (!param_location[selected_parameter_id]) {
          if (is_text_type) {
            param_location[selected_parameter_id] = {'val': ''};
            param_location[selected_parameter_id]['lang'] = {}
            for (let lang of this.StoreLangList.data) {
              if (!param_location[selected_parameter_id]['lang'][lang.label]) {
                param_location[selected_parameter_id]['lang'][lang.label] = '';
              }
            }
          } else {
            param_location[selected_parameter_id] = {'val': {'value': ''}};
          }
        }
        this.d_editParameter = selected_parameter_id;
        this.d_editPageInfoIndex = page_info_index;
        this.d_editPageInfoDetailIndex = page_info_detail_index;
        if (selected_parameter_id === '19') {
          let query = '';
          let data = {};
          this.d_systemDocumentList = [];
          ServiceFunctions.document_list(query, data)
            .then(resp => {
              if (resp.data.status_code === "11000") {
                let system_product_id = localStorage.getItem('system_product_id');
                for (let i in resp.data.list) {
                  let document_url = API_BASE_URL + 'v1/document/get?__sys__pro__id=' + system_product_id + '&doc=' + resp.data.list[i];
                  this.d_systemDocumentList.push(document_url);
                }
                this.d_imageUploadModal = true;
              } else {
                let txt = 'status_code = ' + resp.data.status_code + '\n';
                txt += 'status_message = ' + resp.data.status_message;
                alert(txt);
              }
            });

        }
      }
      this.$forceUpdate();
    },
    f_selectImage: function (document_url) {
      this.d_selectedParamlocation['19'].val = document_url;
      this.d_selectedParamlocation['19'].lang = {};
      for (let lang of this.StoreLangList.data) {
        if (!this.d_selectedParamlocation['19'].lang[lang.label]) {
          this.d_selectedParamlocation['19'].lang[lang.label] = document_url;
        }
      }
      this.d_imageUploadModal = false;
      this.$forceUpdate();
    },
    f_documentRecord: function (file_data) {
      let query = '';
      let data = {'file': file_data};
      ServiceFunctions.document_record(query, data)
        .then(resp => {
          if (resp.data.status_code === "11000") {
            let system_product_id = localStorage.getItem('system_product_id');
            let document_url = API_BASE_URL + 'v1/document/get?__sys__pro__id=' + system_product_id + '&doc=' + resp.data.document_name;
            // console.log('document_url: ', document_url);
            this.d_selectedParamlocation['19'].val = document_url;
            this.d_selectedParamlocation['19'].lang = {};
            for (let lang of this.StoreLangList.data) {
              if (!this.d_selectedParamlocation['19'].lang[lang.label]) {
                this.d_selectedParamlocation['19'].lang[lang.label] = document_url;
              }
            }
            this.d_systemDocumentList.push(document_url);
            this.$forceUpdate();
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_documentUpdate: function (file_data, doc) {
      let query_doc = doc.split('&')[1].replace('doc=','')
      let query = 'doc=' + query_doc;
      let data = {'file': file_data};
      this.d_isImageDocLoading = true;
      ServiceFunctions.document_record(query, data)
        .then(resp => {
          if (resp.data.status_code === "11000") {
            let system_product_id = localStorage.getItem('system_product_id');
            let document_url = API_BASE_URL + 'v1/document/get?__sys__pro__id=' + system_product_id + '&doc=' + resp.data.document_name + '&date=' + new Date().toISOString().split('.')[0];
            // console.log('document_url: ', document_url);
            this.d_selectedParamlocation['19'].val = document_url;
            this.d_selectedParamlocation['19'].lang = {};
            for (let lang of this.StoreLangList.data) {
              if (!this.d_selectedParamlocation['19'].lang[lang.label]) {
                this.d_selectedParamlocation['19'].lang[lang.label] = document_url;
              }
            }
            this.d_updateImage = '';
            for(let i in this.d_systemDocumentList) {
              if(this.d_systemDocumentList[i] == doc) {
                this.d_systemDocumentList[i] = document_url;
                break;
              }
            }
            setTimeout(function () {
              this.d_isImageDocLoading = false;
              this.$forceUpdate();
            }.bind(this), 500);
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_addImageToDocuments: function (doc) {
      if (this.d_tmpFile) {
        // console.log(this.d_tmpFile);
        let file_name = this.d_tmpFile.name;
        let arr = this.d_tmpFile.name.split('.');
        let fileExtension = arr[arr.length - 1];
        if (["jpg", "jpeg", "png", "ico"].indexOf(fileExtension.toLowerCase()) === -1) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert(this.StoreLangTranslation.data['file_extension_type_is_not_eligible'][this.StoreLang]);
          return;
        }
        if (this.d_tmpFile.size > 10485760) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert(this.StoreLangTranslation.data['file_size_is_not_eligible'][this.StoreLang], ' : ', this.d_tmpFile.size);
          return;
        }
        var reader = new FileReader();
        var vm = this;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          // console.log(reader.result);
          // console.log('page_info_index ', page_info_index);
          // console.log('page_info_detail_index ', page_info_detail_index);
          // console.log('vm.d_frdWebunitData.page_info ', vm.d_frdWebunitData.page_info);
          // console.log('reader.result; ', reader.result);
          if(doc){
            vm.f_documentUpdate(reader.result, doc);
            // console.log("if doc içi");
          } else {
            vm.f_documentRecord(reader.result);
            // console.log("else doc içi");
          }
          vm.$forceUpdate();
        }, false);
        reader.readAsDataURL(this.d_tmpFile);
        this.d_tmpFile = null;
        this.$forceUpdate();
      }
    },
    f_createAcceptedDocumentTypes: function () {
      let accept = '';
      let file_types = ["jpg", "jpeg", "png", "ico"];
      for (let i in file_types) {
        accept += '.' + file_types[i] + ',';
      }
      return accept;
    },
    f_newPageItem: function () {
      // console.log('this.p_WebsiteUnitData.data ', this.p_WebsiteUnitData.data);
      if (!this.p_WebsiteUnitData.data['0']['7']) {
        this.p_WebsiteUnitData.data['0']['7'] = {'list': []};
      }
      // general tipinde bir komponent ekliyoruz.
      let new_item = {"9": {"list": []}, "27": {"val": {"value": "0"} }, "8": {"val": "new komponent caption", "lang": {}} };
      for (let lang of this.StoreLangList.data) {
        if (!new_item['8']['lang'][lang.label]) {
          new_item['8']['lang'][lang.label] = 'new komponent caption';
        }
      }

      this.p_WebsiteUnitData.data['0']['7'].list.push(new_item);
      this.$forceUpdate();
    },
    f_newPageContent: function (page_info) {
      // console.log(page_info);
      // general tipinde bir komponent ekliyoruz.
      let new_item = {};
      if (!page_info['9']) {
        page_info['9'] = {'list': []};
      }
      page_info['9'].list.push(new_item);
      this.$forceUpdate();
    },
    f_copyPageInfo: function (list, page_info_index) {
      let copied = JSON.parse(JSON.stringify(list[page_info_index]));
      list.push(copied);
      this.$forceUpdate();
    },
    f_deleteListItem: function (list, page_info_index) {
      list.splice(page_info_index, 1);
      this.$forceUpdate();
    }, 
    f_listParamOrder: function (list, index, direction) {
      // console.log('list:', list, 'index:', index, 'direction:', direction);
      let index_data = list[index];
      let count = list.length;
      let friend = '';
      if (direction === 'up') {
        if (index !== 0) {
          friend = list[index - 1];
          list[index - 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'down') {
        if (index !== count - 1) {
          friend = list[index + 1];
          list[index + 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'top') {
        list.splice(index, 1);
        list.splice(0, 0, index_data);
      } else if (direction === 'bottom') {
        list.splice(index, 1);
        list.push(index_data);
      }
      this.$forceUpdate();
    },
    f_wanalzyerProjectIdType: function (page_info_detail) {
      let getting_analysis_output_with_column_inputs_id_type = 'no';
      try {
        if (page_info_detail['29']['val']['value'] === '0') {
          getting_analysis_output_with_column_inputs_id_type = 'no';
        } else if (page_info_detail['29']['val']['value'] === '1') {
          getting_analysis_output_with_column_inputs_id_type = 'client_id_based';
        } else if (page_info_detail['29']['val']['value'] === '2') {
          getting_analysis_output_with_column_inputs_id_type = 'based_on_wdmr_id';
        } else if (page_info_detail['29']['val']['value'] === '3') {
          getting_analysis_output_with_column_inputs_id_type = 'based_on_client_id_or_wdmr_id';
        }
      } catch(err) {}
      return getting_analysis_output_with_column_inputs_id_type;
    },
    f_getImageLink: function (page_info_detail) {
      let image_link = '';
      try {
        image_link = page_info_detail['22'] && page_info_detail['22'].lang && page_info_detail['22'].lang[this.StoreLang] ? page_info_detail['22']['lang'][this.StoreLang] : page_info_detail['19'] && page_info_detail['19'].lang && page_info_detail['19'].lang[this.StoreLang] ? page_info_detail['19']['lang'][this.StoreLang] : '';
      } catch(err) {
        console.log(err);
      }
      // console.log('image_link ', image_link);
      return image_link;
    },
    f_dateFormat: function (date, date_type='year-month-day') {
      if (date) {
        if (date_type === 'year-month-day') {
          if (this.StoreLang === 'tr') {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
            return x;
          } else {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("YYYY/MM/DD");
            return x;
          }
        } else if (date_type === 'year-month-day hh:mm') {
          let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm");
          return x;
        }
      } else {
        return "";
      }
    },
    f_createHrefLink: function (url) {
      let href_url = '';
      if (url.indexOf('http') !== -1) {
        href_url = url;
      } else {
        // console.log(window.location);
        if (url) {
          if (url[0] === '/') {
            // href_url = window.location.origin + '/#' + url;
            href_url = window.location.origin + '/' + url;
          } else {
            // href_url = window.location.origin + '/#/' + url;
            href_url = window.location.origin + '/' + url;
          }
        } else {
          href_url = window.location.origin;
        }
      }
      return href_url;
    },
    f_goToThisUrl: function (url) {
      if (url.indexOf('http') !== -1) {
        window.open(url, '_blank');
      } else {
        // window.open(window.location.origin + '/#/' + url, '_blank');
        window.open(window.location.origin + '/' + url, '_blank');
      }
    },
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    },
    f_wanalyzerMachinelearningDataGet: function (machine_learning_id) {
      let query = 'machine_learning_id=' + machine_learning_id;
      let data = {};
      ServiceFunctions.wanalyzer_machinelearning_data_get(query, data)
        .then(resp => {
          if (resp.data.status_code === "11000") {
            this.d_machineLearningList[machine_learning_id] = resp.data.machine_learning;
            this.$forceUpdate();
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_prepareWebsiteunitForSaving: function () {
      let website_unit_data = {
          'status': '0',
          'show_in_menu': '1',
          'menu_caption': {},
          'click_type': '0',
          'route_path': {},
          'page_top_caption': {},
          'is_dashboard': '0',
          'website_unit_no': {},
          'parent_website_unit_no': {},
          'route_name': {},
          'page_info': [],
          'authorization_status': '0',
          'authorization_code': {},
      }
      try {
        website_unit_data['status'] = this.p_WebsiteUnitData['data']['0']['0']['val']['value']
      } catch(err) {}
      try {
        website_unit_data['show_in_menu'] = this.p_WebsiteUnitData['data']['0']['1']['val']['value']
      } catch(err) {}
      try {
        website_unit_data['menu_caption'] = this.p_WebsiteUnitData['data']['0']['2']['lang']
      } catch(err) {}
      try {
        website_unit_data['click_type'] = this.p_WebsiteUnitData['data']['0']['3']['val']['value']
      } catch(err) {}
      try {
        website_unit_data['route_path'] = this.p_WebsiteUnitData['data']['0']['4']['lang']
      } catch(err) {}
      try {
        website_unit_data['page_top_caption'] = this.p_WebsiteUnitData['data']['0']['5']['lang']
      } catch(err) {}
      try {
        website_unit_data['is_dashboard'] = this.p_WebsiteUnitData['data']['0']['6']['val']['value']
      } catch(err) {}
      try {
        website_unit_data['website_unit_no'] = this.p_WebsiteUnitData['data']['0']['23']['lang']
      } catch(err) {}
      try {
        website_unit_data['parent_website_unit_no'] = this.p_WebsiteUnitData['data']['0']['24']['lang']
      } catch(err) {}
      try {
        website_unit_data['route_name'] = this.p_WebsiteUnitData['data']['0']['25']['lang']
      } catch(err) {}
      if ('7' in this.p_WebsiteUnitData['data']['0'] && this.p_WebsiteUnitData['data']['0']['7']['list'].length > 0) {
        for (let page_item of this.p_WebsiteUnitData['data']['0']['7']['list']) {
          let new_page_item = {
              'page_info_caption': {},
              'component_type': '',
              'wai_search_type': ''
          }
          try {
            new_page_item['page_info_caption'] = page_item['8']['lang']
          } catch(err) {}
          try {
            new_page_item['component_type'] = page_item['27']['val']['value']
          } catch(err) {}
          try {
            new_page_item['wai_search_type'] = page_item['33']['val']['value']
          } catch(err) {}

          new_page_item['page_info_detail'] = []
          if ('9' in page_item && 'list' in page_item['9'] && page_item['9']['list'].length > 0) {
            for (let page_info_detail of page_item['9']['list']) {
              let new_page_info_detail = {
                  'page_info_detail_caption': {},
                  'page_info_detail_note': {},
                  'button_name': {},
                  'code': {},
                  'link': {},
                  'method_name': {},
                  'trigger_point': {},
                  'image_name': {},
                  'mail': {},
                  'iframe': {},
                  'img_link': {},
                  'wanalyzer_stats_id': {},
                  'machine_learning_id': {},
                  'wanalyzer_project_id': {},
                  'image_base64': {},
                  'wanalyzer_project_usage_type': '',
                  'getting_analysis_output_with_column_inputs_id_type': '',
              }
              try {
                new_page_info_detail['page_info_detail_caption'] = page_info_detail['10']['lang']
              } catch(err) {}
              try {
                new_page_info_detail['page_info_detail_note'] = page_info_detail['26']['lang']
              } catch(err) {}
              try {
                new_page_info_detail['button_name'] = page_info_detail['11']['lang']
              } catch(err) {}
              try {
                new_page_info_detail['code'] = page_info_detail['12']['lang']
              } catch(err) {}
              try {
                new_page_info_detail['link'] = page_info_detail['13']['lang']
              } catch(err) {}
              try {
                new_page_info_detail['method_name'] = page_info_detail['14']['lang']
              } catch(err) {}
              try {
                new_page_info_detail['trigger_point'] = page_info_detail['15']['lang']
              } catch(err) {}
              try {
                new_page_info_detail['image_name'] = page_info_detail['16']['lang']
              } catch(err) {}
              try {
                new_page_info_detail['mail'] = page_info_detail['17']['lang']
              } catch(err) {}
              try {
                new_page_info_detail['iframe'] = page_info_detail['18']['lang']
              } catch(err) {}
              try {
                new_page_info_detail['img_link'] = page_info_detail['19']['lang']
              } catch(err) {}
              try {
                new_page_info_detail['wanalyzer_project_id'] = page_info_detail['21']['lang']
              } catch(err) {}
              try {
                new_page_info_detail['machine_learning_id'] = page_info_detail['20']['lang']
              } catch(err) {}
              try {
                new_page_info_detail['wanalyzer_stats_id'] = page_info_detail['32']['lang']
              } catch(err) {}
              try {
                new_page_info_detail['wanalyzer_project_usage_type'] = page_info_detail['28']['val']['value']
              } catch(err) {}
              try {
                new_page_info_detail['getting_analysis_output_with_column_inputs_id_type'] = page_info_detail['29']['val']['value']
              } catch(err) {}
              try {
                new_page_info_detail['image_base64'] = page_info_detail['22']['lang']
              } catch(err) {}
              if (!new_page_item['page_info_detail']) {
                new_page_item['page_info_detail'] = []
              }
              new_page_item['page_info_detail'].push(new_page_info_detail)
            }
          }
          website_unit_data['page_info'].push(new_page_item)
        }
      }
      return website_unit_data;
    },
    f_frontendWebsiteUnitUpdate: function () {
      if (this.d_editParameter !== '' && this.d_selectedParamlocation !== ''){
        if(this.d_inputEditorMode == "html"){
          for (let lang of this.StoreLangList.data) {
            let div_el = document.getElementById("website_html_edit_" + lang.label);
            this.d_selectedParamlocation[this.d_editParameter]['lang'][lang.label] = div_el.innerHTML;
          }
        }
        this.d_editParameter = '';
        this.d_editPageInfoIndex = '';
        this.d_selectedParamlocation = '';
      }
      setTimeout(function () {
        let website_doc = this.f_prepareWebsiteunitForSaving();
        // console.log('website_doc : ', website_doc);
        let query = 'frontend_client_id=' + this.p_WebsiteUnitData.parent_c.id;
        query += '&websiteunit_client_id=' + this.p_WebsiteUnitData.client_id;
        let data = { 'websiteunit': website_doc };
        ServiceFunctions.frontend_website_unit_new(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            } else {
              alert(resp.data.status_code + ' = ' + resp.data.status_message);
            }
          });
      }.bind(this), 50);

    },
    f_openUpdateImage: function (doc) {
      this.d_updateImage = doc;
    },
    f_deleteImage: function (doc) {
      if (confirm(this.StoreLangTranslation.data['delete_image_confirm'][this.StoreLang])) {
        let query_doc = doc.split('&')[1];
        let query = query_doc;
        let data = {};
        ServiceFunctions.document_delete(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              let delete_index = '';
              for (let i in this.d_systemDocumentList) {
                if (this.d_systemDocumentList[i] == doc) {
                  console.log(doc + ' found');
                  delete_index = parseInt(i);
                  break;
                }
              }
              this.d_systemDocumentList.splice(delete_index, 1);
              this.d_selectedParamlocation['19'].val = '';
              this.d_selectedParamlocation['19'].lang = {};
              for (let lang of this.StoreLangList.data) {
                if (!this.d_selectedParamlocation['19'].lang[lang.label]) {
                  this.d_selectedParamlocation['19'].lang[lang.label] = '';
                }
              }
            } else {
              alert(resp.data.status_code + ' = ' + resp.data.status_message);
            }
          });
        }
    }
  },
  watch: {
    'StoreWebsiteEdit': function () {
      if (this.StoreWebsiteEdit === 'yes') {
        this.d_slidingInterval = 0;
      } else {
        this.d_slidingInterval = 10000;
      }
    }
  }
}

</script>

<style type="text/css">
.component_9 {
  height: 100%;
  background-color: rgba(252, 252, 252, 1.0);
  border-radius: 20px 20px;
  box-shadow: rgb(245 245 245) 0px 0px 2px 0px;
  border: none;
}

.component_9:hover {
  /*width: 101%;*/
  /*height: 101%;*/
  background-color: rgba(252, 252, 252, 1.0);
  border-radius: 20px 20px;
  box-shadow: rgb(245 245 245) 1px 1px 2px 2px;
  border: none;
  /*font-size: 18px;*/
  /*color: #20b2aa;*/
  color: royalblue;
}

.component_10 {
  height: 100%;
  background-color: rgba(252, 252, 252, 1.0);
  border-radius: 20px 20px;
  box-shadow: rgb(245 245 245) 0px 0px 2px 0px;
  border: none;
}

.component_10:hover {
  /*width: 101%;*/
  /*height: 101%;*/
  background-color: rgba(252, 252, 252, 1.0);
  border-radius: 20px 20px;
  box-shadow: rgb(245 245 245) 1px 1px 2px 2px;
  border: none;
  /*font-size: 18px;*/
  /*color: #20b2aa;*/
  color: royalblue;
}

.carousel-item img {
    min-height: 400px!important;
}

.fade-in-page {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.activeButton{

}

</style>

